const ACTION_NAME = 'NAVIGATION';
const ACTION = (action) => `${ACTION_NAME}_${action}`;
// ACTIONS
export const ADD = ACTION('ADD');
export const SET = ACTION('SET');
export const GET = ACTION('GET');

const Actions = {
    ADD, SET, GET,
};
export default Actions;

// SETTERS

// GETTERS


// MAPPING
export const mapStateToProps = (state) => ({
    ...state.main,
    ...state.general,
    ...state.navigation,
});

