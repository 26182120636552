import React, {Component,} from 'react'
import '../../Styles/MainContent.sass'
import {Link} from 'react-router-dom';
import {currentSeries, currentSermon} from '../../../Assets/CurrentSermon';
import {setCurrentSeries, setCurrentSermon} from '../../../redux/actions/ActionsSermons';
import Icons from '../../../Assets/Icons';
import SVG from "../../Stateless/SVGComponent";

export class MainContent extends Component {

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    componentWillMount() {
        currentSermon().then(sermon => {
            this.props.dispatch(setCurrentSermon(sermon));
        });
        currentSeries().then(sermon => {
            this.props.dispatch(setCurrentSeries(sermon));
        });
    }

    render() {
        return (
            <main id={'main'}>
                <main id={'mainContent'}>
                    <div className={`${this.props.isMobile ? '' : 'height-spacer medium'}`}/>
                    <div className={'current wrapper'}>
                        {
                            this.props.currentSeries && CurrentSermonSeriesArticle(this.props.currentSeries)
                        }
                    </div>
                    <div className={`${this.props.isMobile ? '' : 'height-spacer medium'}`}/>
                </main>
            </main>
        );
    }
}

const CurrentSermonSeriesArticle = (series) => {
    let {title, content,} = series;

    if (series === 'undefined') {
        return (null);
    }

    return (<article>
        <h4 className={'subject'}>Predigtreihe</h4>
        <h1 className={'title'}>{title}</h1>
        <div className={'paragraphs'}>
            {
                content.map((paragraph, idx) => (<p key={`paragraph-${idx}`}>{paragraph}</p>))
            }
        </div>
        <div className={'divider left'}/>
        <div className={'last-sermon'}>
            <p>
                <span className={'last'}>Letzte Predigt:</span>
                <span className={'last-title'}>
                    <Link to={'/predigt'}>Was jetzt? <span
                        className={'icon arrow'}><SVG {...Icons.arrows.right}/></span></Link>
            </span>
            </p>
        </div>
    </article>)
};

export default MainContent;
