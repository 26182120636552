const ACTION_NAME = 'ARTICLES';
const ACTION = (action) => `${ACTION_NAME}_${action}`;
// ACTIONS
export const ADD = ACTION('ADD');
export const SET = ACTION('SET');
export const SET_ACTIVE = ACTION('SET_ACTIVE');
export const GET = ACTION('GET');

const Actions = {
    ADD, SET, GET, SET_ACTIVE,
};
export default Actions;

// SETTERS
export const addArticle = (key, article) => ({
    type: ADD, key, article,
});

export const setArticles = (articles) => ({
    type: SET, articles,
});

export const setActiveArticles = (article) => ({
    type: SET_ACTIVE, article,
});

// GETTERS


// MAPPING
export const mapStateToProps = (state) => ({
    ...state.main,
    ...state.general,
    ...state.articles,
});

export const mapDispatchToProps = dispatch => ({
    setActiveArticles: (title) => {
        dispatch(setActiveArticles(title))
    },
    fetchArticles: (articles) => {
        dispatch(setArticles(articles))
    },
});

