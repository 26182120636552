const getCurrentSermon = async () => {
    const r = require('./content/current-sermon.json');
    let fname = r.path;
    return require('./content' + fname)
};
const getCurrentSeries = async () => {
    const r = require('./content/current-series.json');
    let fname = r.path;
    return require('./content' + fname)
};

export const currentSermon = () => getCurrentSermon();
export const currentSeries = () => getCurrentSeries();
export default currentSermon;

