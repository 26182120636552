import React from 'react';
import {Gradient} from "react-gradient";

export const GradientWrapper = (props) => {
    const {gradient, children} = props;
    console.error(props);
    return (
        <Gradient
            className={'article wrapper'}
            gradients={gradient} // required
            // property='background'
            duration={2000}
            angle='45deg'
            // transitionType='sequential'
        >
            {children}
        </Gradient>
    );
};

export default GradientWrapper;