import React from 'react';

const CurrentEvent = props => {
    let dayText = days => days === 1 ? 'Tag' : 'Tage';

    return (
        <div id={'current-event'} className={'wrapper sides'}>
            <p>
                <span className={'pre-txt'}>Noch </span>
                <span className={'days'}>{props.days} </span>
                <span className={'days-txt'}>{dayText(props.days)} </span>
                <span className={'post-txt'}>{props.post} </span>
                <span className={'event-txt'}>{props.event} </span>
                <span className={'post-txt'}>!</span>
            </p>
        </div>
    )
};

export default CurrentEvent;