import Actions from '../actions/ActionsGeneral';
import {isMobile} from "../../utils/HelperFunctions";

const initialState = {
    isMobile: isMobile(),
    headerShadow: '',
    shadows: {
        header: false,
    },
};

const ReducerGeneral = (state = initialState, payload) => {
    let {type, key, val} = payload;
    let newState = Object.assign({}, state);

    switch (type) {
        case Actions.SET:
            newState[key] = val;
            break;
        case Actions.SET_SHADOWS:
            if (!('shadows' in newState)) {
                newState['shadows'] = {};
            }
            newState['shadows'][key] = val;
            newState['shadows'] = JSON.parse(JSON.stringify(newState.shadows));
            break;
        case Actions.REMOVE:
            break;
        default:
            break;
    }

    return newState;
};

export default ReducerGeneral;
