import {applyMiddleware, combineReducers, createStore} from 'redux'
import reducers from './reducers'
import logger from "redux-logger"

const store = createStore(
    combineReducers(
        reducers
    ),
    applyMiddleware(logger)
);

export default store;
