import {connect} from 'react-redux';
import Header from './Header';
import {mapStateToProps} from '../../../redux/actions/ActionsHeader';
import {scrolledPastThreshold, toggleShadow} from '../../../utils/display';

const mapDispatchToProps = dispatch => ({
    toggleHeaderShadow: (prevVal) => {
        const newVal = scrolledPastThreshold();
        if (newVal !== prevVal) {
            dispatch(toggleShadow('header', newVal));
        }
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Header)
