import React from 'react';
import Icons from '../../../Assets/Icons';
import SVG from '../SVGComponent';
import {sortByIndex} from "../../../utils/HelperFunctions";

const SocialMediaIcons = (props) => {
    const {isMobile} = props;
    return (
        <div id={'social-icons'} className={`wrapper sides ${isMobile ? 'mobile' : ''}`}>{
            sortByIndex(Icons.socialmedia)
                .map((icon) => {
                    return <a key={icon.id} className={'icon wrapper'} target={'_blank'}
                              href={icon.href}>
                        <SVG {...icon}/>
                    </a>
                })
        }</div>
    );
};

export default SocialMediaIcons;
