import {ReactComponent as impact} from './logos/IMPACT-Logo.svg';
import {ReactComponent as teens} from './logos/IMPACT-Teens.svg';

export const logoIMPACT = {
    id: 'logo-impact',
    src: impact,
};
export const logoTeens = {
    id: 'logo-teens',
    src: teens,
};

const Logos = {
    impact: logoIMPACT,
    teens: logoTeens,
};

export default Logos;