import Actions from '../actions/ActionsArticles';

const initialState = {
    articles: {},
    activeArticle: undefined,
    loaded: false,
};

const ReducerArticles = (state = initialState, payload) => {
    let {type, key, article, articles} = payload;
    let newState = Object.assign({}, state);

    switch (type) {
        case Actions.ADD:
            newState.articles[key] = article;
            break;
        case Actions.SET:
            newState.articles = articles;
            break;
        case Actions.SET_ACTIVE:
            newState.activeArticle = article;
            newState.loaded = true;
            break;
        case Actions.REMOVE:
            break;
        default:
            break;
    }

    return newState;
};

export default ReducerArticles;