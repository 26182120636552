import React from 'react';
import Header from "../../Stateful/Header";
import HomePageNavigation from "../../Stateful/HomePageNavigation";
import MainContent from "../../Stateful/MainContent";
import Footer from "../../Stateful/Footer";
import {toggleBodyScroll} from "../../../utils/articles";

export const Home = () => {
    toggleBodyScroll(false);
    return (
        <div id={'root'}>
            <Header/>
            <HomePageNavigation/>
            <MainContent/>
            <Footer/>
        </div>
    );
};

export default Home;
