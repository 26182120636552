import React from 'react';
import Logos from '../../../Assets/Logos';
import SVG from "../SVGComponent";

export const CenterLogo = () => {
    return (
        <div id={'logo'} className={'wrapper'}>
            <SVG {...Logos.impact}/>
        </div>
    )
};

export default CenterLogo;