import React, {Component} from 'react'
import GradientWrapper from "../../GradientWrapper"
import {gradients} from "../../../../utils/articles"
import ArticleHeaderNav from "../_Part/ArticleHeaderNav"
import Calendar from "react-calendar"
import "./style.sass"

class Events extends Component {
    render() {
        console.log(this.props);
        const {match, articles} = this.props;

        const key = match.params[1];
        const article = articles[key];

        return (
            <div>
                <GradientWrapper gradient={[gradients.events_dark]}>
                    <div>
                        <ArticleHeaderNav/>

                        <article className={'article'}>
                            <h4 className={'subject'}>{article.pre_title}</h4>
                            <h1 className={'title'}>{article.title}</h1>

                            <div className={"calendar wrapper"}>

                                <Calendar
                                />

                            </div>
                        </article>


                    </div>
                </GradientWrapper>
            </div>
        );
    }
}

export default Events;
