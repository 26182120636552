const ACTION_NAME = 'MAIN';
const ACTION = (action) => `${ACTION_NAME}_${action}`;

// ACTIONS
export const SET = ACTION('SET');
export const GET = ACTION('GET');

const Actions = {
    SET, GET,
};
export default Actions;

// SETTERS
export const setInitialized = (isInitialized) => ({
    type: SET,
    key: "initialized",
    val: isInitialized,
});

// GETTERS

// MAPPING
export const mapStateToProps = (state) => ({
    ...state.main,
    ...state.general,
});

