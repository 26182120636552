import React, {Component} from 'react'
import '../../Styles/IMPACTMain.sass'
import Router from '../../Routing/Router'
import {isMobile} from '../../../utils/HelperFunctions'
import SplashPage from '../../Stateless/SplashPage'
import {Loads} from "react-loads";
import {fetchArticles} from "../../../utils/articles";
import {currentSermon} from "../../../Assets/CurrentSermon";

class IMPACTMain extends Component {

    resized = () => {
        let mob = isMobile();
        if (!(mob && this.props.isMobile))
            this.props.onResize(mob);
        return mob;
    };

    componentWillMount() {
        const delay = Math.random() * 1500 + 500;

        this.setState({
            delay,
            initialized: false,
        });

        window.addEventListener('resize', () => this.resized());
    }

    saveArticles = (resp) => {
        this.props.fetchArticles(resp);

        currentSermon().then(resp => {
            this.props.initCurrentSermon(resp);
        });

        setTimeout(() => {
            this.setState({
                showSplash: false,
                loading: false,
            });

            setTimeout(() => {
                const splash = () => document.getElementById("splash-view");
                const ele = splash();
                console.warn(ele, ele ? ele.style.opacity : "-------");
                if (ele && ele.style.visibility === "hidden") {
                    this.setState({
                        hideSplash: true
                    });
                }
            }, 500 + 1000)
        }, this.state.delay);
    };

    componentDidMount() {
        // const {delay, loading} = this.state;
        // if(loading){
        //     // init articles
        //     fetchArticles().then(resp => {
        //         this.props.fetchArticles(resp);
        //
        //         currentSermon().then(resp => {
        //             this.props.initCurrentSermon(resp);
        //         });
        //
        //         setTimeout(() => {
        //             this.setState({
        //                 showSplash: false,
        //                 loading: false,
        //             });
        //
        //             setTimeout(() => {
        //                 const splash = () => document.getElementById("splash-view");
        //                 const ele = splash();
        //                 console.warn(ele, ele ? ele.style.opacity : "-------");
        //                 if (ele && ele.style.visibility === "hidden") {
        //                     this.setState({
        //                         hideSplash: true
        //                     });
        //                 }
        //             }, 500 + 1000)
        //         }, delay);
        //     });
        // }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !((nextProps === this.props) || (nextState === this.state))
    }

    componentWillUnmount() {
        console.warn('componentWillUnmount');
        window.removeEventListener('resize', () => this.resized());
    }

    loadData = () => fetchArticles();

    render() {
        console.error(this.props);
        if (this.props.initialized) {
            return (
                <Router/>
            )
        }

        return (
            <div id={'homePage'}>
                {/*/!*{!this.state.hideSplash && <SplashPage visible={this.state.showSplash && this.state.loading}/>}*!/*/}
                {/*{!this.state.loading && <Router/>}*/}

                <Loads load={this.loadData}>
                    {({response, error, load, isRejected, isPending, isResolved}) => {
                        this.props.setInitialized(true);
                        this.saveArticles(response);
                        console.log();
                        return (
                            <div>
                                {isPending && <SplashPage visible={true}/>}
                                {isResolved && (
                                    <Router/>
                                )}
                                {isRejected && <div type="danger">{error.message}</div>}
                            </div>
                        )
                    }}
                </Loads>
            </div>
        );
    }
}

export default IMPACTMain;
