const ACTION_NAME = 'SERMON';
const ACTION = (action) => `${ACTION_NAME}_${action}`;
// ACTIONS
export const ADD = ACTION('ADD');
export const SET = ACTION('SET');
export const GET = ACTION('GET');
export const SET_CURRENT = ACTION('CURRENT');

const Actions = {
    ADD, SET, GET, SET_CURRENT,
};
export default Actions;

// SETTERS
export const addSermon = (key, sermon) => ({
    type: ADD, key, sermon,
});

export const setSermons = (sermons) => ({
    type: SET, sermons,
});

export const setCurrentSermon = (sermon) => ({
    type: SET_CURRENT, key: 'current', val: sermon,
});

export const setCurrentSeries = (series) => ({
    type: SET_CURRENT, key: 'currentSeries', val: series,
});

// GETTERS


// MAPPING
export const mapStateToProps = (state) => ({
    ...state.main,
    ...state.general,
    ...state.sermons,
});

