import React, {Component} from 'react'
import '../../Styles/Header.sass'
import BurgerMenu from '../../Mobile/BurgerMenu';
import CurrentEvent from '../../Stateless/CurrentEvent';
import SocialMediaIcons from '../../Stateless/SocialMediaIcons';
import CenterLogo from "../../Stateless/CenterLogo";

const isShadowed = (state) => state ? ' softShadow ' : '';

class Header extends Component {

    rightComponent = () => {
        if (this.props.isMobile) {
            return <BurgerMenu/>
        }
        return <CurrentEvent days={2} post={'bis zum'} event={'Fire-Up'}/>
    };

    componentDidMount() {
        document.addEventListener('scroll', () => this.props.toggleHeaderShadow(this.props.shadows.header));
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', () => this.props.toggleHeaderShadow(this.props.shadows.header));
    }

    componentWillMount() {
    }

    render() {
        return (
            <header id={'header'}>
                <div className={'wrapper noShadow trans long delay ' + isShadowed(this.props.shadows.header)}>
                    <SocialMediaIcons/>
                    <CenterLogo/>
                    {
                        this.rightComponent()
                    }
                </div>
            </header>
        );
    }
}

export default Header;
