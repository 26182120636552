import React from 'react';
import {Link} from 'react-router-dom';
import '../Styles/NavigationItem.sass';
import {toggleArticle} from "../../../utils/articles";

export const NavigationItem = props => {
    const {link_title, title, classNames, index, href, pre_title, pre_href} = props.item;
    const t = pre_title[0] ? `${pre_title[1]}-` : '';
    return (
        <li key={`${index}:{${t}${title}}`} className={`${props.isMobile ? 'mobile' : ''} ${classNames}`}>
            <div className={'item wrapper long trans'}>
                <Link to={`${pre_href}${href}/`}>
                    {
                        !props.isMobile && (
                            <span onClick={() => {
                                toggleArticle(true);
                            }}>
                                <div className={'link wrapper trans'}>
                                    {link_title}
                                </div>
                            </span>
                        )
                    }
                    {
                        props.isMobile && (
                            <div className={'link wrapper trans'}>
                                <span onClick={() => {
                                    toggleArticle(true);
                                }}>
                                        {link_title}
                                </span>
                            </div>
                        )
                    }
                </Link>
            </div>
        </li>
    )
};

export default NavigationItem;
