import React from 'react';
import {Link} from 'react-router-dom';

const FooterCol = props => {
    let {category, links} = props;
    return (
        <div key={category} className={'footer-column wrapper'}>
            <h4 className={'header'}>{category}</h4>
            <div className={'divider left'}/>
            <div className={'footer-items wrapper'}>
                <ul className={'footer-items list'}>
                    {
                        Object.keys(links).map((key, idx, arr) => {
                            let item = links[key];
                            let {href, href_absolute, title, target} = item;
                            if (target === '_blank') {
                                return (
                                    <li key={title} className={'footer-item'}>
                                        <a target={target} href={`${href_absolute}`}>
                                            <span>
                                                {title}
                                            </span>
                                        </a>
                                    </li>
                                )
                            }

                            return (
                                <li key={title} className={'footer-item'}>
                                    <Link target={'_self'} to={`/${href}`}>
                                        <span>
                                            {title}
                                        </span>
                                    </Link>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </div>
    )
};

export default FooterCol;