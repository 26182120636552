import Actions from '../actions/ActionsSermons';

const initialState = {
    articles: {},
};

const ReducerSermons = (state = initialState, payload) => {
    let {type, key, val, sermons} = payload;
    let newState = Object.assign({}, state);

    switch (type) {
        case Actions.ADD:
            newState.sermons[key] = val;
            break;
        case Actions.SET:
            newState.sermons = sermons;
            break;
        case Actions.SET_CURRENT:
            newState[key] = val;
            break;
        case Actions.REMOVE:
            break;
        default:
            break;
    }

    return newState;
};

export default ReducerSermons;