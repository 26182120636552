import React, {Component} from 'react';
import '../../Styles/EntrySlideshow.sass';
import Carousel from 'nuka-carousel';
import Images from '../../../Assets/Images';
import {isMobile} from "../../../utils/HelperFunctions";

class EntrySlideshow extends Component {

    settings = () => {
        return {
            withoutControls: true,
            dragging: !this.state.isMobile,
            swiping: this.state.isMobile,
            infinite: true,
            wrapAround: true,
            autoplay: true,
            autoplayReverse: false,
            vertical: false,
            enableKeyboardControls: !this.state.isMobile,
            speed: 800,
            slideIndex: 0,
            slidesToShow: this.state.isMobile ? 1 : 3,
            slidesToScroll: 1,
            autoplayInterval: this.state.isMobile ? 5000 : 3000,
            // initialSlideHeight: 400,
            // initialSlideWidth: 300,
            zoomScale: 1,
            opacityScale: 0.1,
            slideWidth: this.state.isMobile ? 1 : 2,
            // slideHeight: 400,
            cellAlign: 'center',
            cellSpacing: this.state.isMobile ? 0 : 30,
            framePadding: '0',
            frameOverflow: 'hidden',
            edgeEasing: 'easeOutCirc',
            heightMode: 'first',
            transitionMode: this.state.isMobile ? 'scroll' : 'scroll3d',
        }
    };

    constructor(props) {
        super(props);
        this.state = {
            isMobile: false,
        };
    }

    componentWillMount() {
        // const firebaseImageURL = 'https://firebasestorage.googleapis.com/v0/b/impact-f9317.appspot.com/o/Bildschirmfoto%202019-06-29%20um%2022.20.04.png?alt=media&token=fdcb3c01-5e71-475b-9a1a-635ca7e0d228';
        //
        // fetch(firebaseImageURL)
        //     .then(resp => resp.json())
        //     .then(json => {
        //         console.log(json);
        //     })
    }

    componentDidMount() {
        this.setState({
            isMobile: isMobile()
        })
    }

    render() {
        return (
            <div className={'slideshow wrapper softShadow-1'}>
                <Carousel width={'100vw'} {...this.settings()}>
                    {
                        Images.slideshowImgs.map((img, idx, arr) => {
                            return (
                                <div key={img + '-' + idx}
                                     title={img.id}
                                     className={'slide trans wrapper'}
                                     style={{backgroundImage: `url(${img.img})`}}>
                                </div>
                            )
                        })
                    }
                </Carousel>
            </div>
        );
    }
}

export default EntrySlideshow;
