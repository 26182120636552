import React from 'react';
import '../Styles/FooterMaps.sass';
import GoogleMapReact from 'google-map-react';

const FooterMaps = () => {
    return (
        <div className={'maps wrapper'}>
            <GoogleMapReact/>
        </div>
    )
};

export default FooterMaps;
