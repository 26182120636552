import React, {Component} from 'react';
import EntrySlideshow from '../EntrySlideshow';
import '../../Styles/HomePageNavigation.sass';
import articleLinks from '../../../Routing/urls/ArticleLinks';
import NavigationItem from "../../Stateless/NavigationItem";
import {Link} from "react-router-dom";
import {isMobile} from "../../../utils/HelperFunctions";

class HomePageNavigation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            router: {},
            links: articleLinks,
            isMobile: false
        };
    }

    getItem() {
        // if(!this.state.articleLinks) return [];
        let classNames = 'nav-item';
        // let activeClassName = 'active';
        let items = [];


        for (let i = 0; i < articleLinks.length; i++) {
            let item = articleLinks[i];

            item.classNames = classNames;
            item.context = this;
            items.push(item);
        }

        return items;
    }

    renderNavigation() {
        if (isMobile()) {
            return (
                <div className={'height-spacer medium'}/>
            )
        }

        return (
            <nav id={'homepageNav'}>
                <div className={'navList wrapper '}>
                    <ul>{
                        this.getItem().map((item, idx) => <NavigationItem key={idx} {...{item, key: item.index}}/>)
                    }
                        <li>
                            <Link to={"/404-test"}>Error TEST</Link>
                        </li>
                        <li>
                            <a href={"http://localhost:9000/.netlify/functions/bibleAPI"}>FUNCTIONS TEST</a>
                        </li>
                    </ul>
                </div>
            </nav>
        )
    }

    render() {
        return (
            <div className={'homepageNav wrapper'}>
                <div className={'height-spacer medium-1'}/>
                <h1 className={'welcome'}>Willkommen</h1>
                {
                    this.renderNavigation()
                }
                <EntrySlideshow/>
            </div>
        );
    }
}

export default HomePageNavigation;
