export const fetchArticles = async () => {
    const json = require('../Routing/json/Articles.json');
    return json.articles;
};

export const toggleBodyScroll = state => {
    let className = 'noScroll';
    let func = state ? (name) => document.body.classList.add(name) : (name) => document.body.classList.remove(name);
    func(className);
};

export const toggleArticle = state => {
    if (state) {
        document.body.classList.add('noScroll');
    } else {
        document.body.classList.remove('noScroll');
    }
};

export const colors = {
    'main': '#555d6e',
    'light-acc': '#c2defc',
    'dark-grey': '#373737',
    'main-1': '#ff3500',
    'accent': '#195AFC',
    'accent-alt': '#19FCE3',
    'lgrey': '#9A9A9A',
    'llgrey': '#EEEEEE',
    'llgrey1': '#eaeaea',
    'white': '#ffffff',
    'black': '#000000',
};

export const gradients = {
    events: ['#2d6dfc', '#528dfc'],
    events_light: ['#e3e3e3', '#ffffff'],
    events_dark: [colors["dark-grey"], colors["dark-grey"],],
    blue: ['#2d6dfc', '#528dfc'],
    blue1: ['#195AFC', '#528dfc'],
};
