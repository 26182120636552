import React, {Component} from 'react';

class Privacy extends Component {
    render() {
        return (
            <div>
                {this.props.match.params[0]}
            </div>
        );
    }
}

export default Privacy;