import {connect} from 'react-redux'
import IMPACTMain from './IMPACTMain'
import {mapStateToProps, setInitialized} from '../../../redux/actions/ActionsMain'
import {mapDispatchToProps as mapDispatch} from '../../../redux/actions/ActionsArticles'
import {setIsMobile} from '../../../redux/actions/ActionsGeneral'
import {setCurrentSermon} from "../../../redux/actions/ActionsSermons";

const mapDispatchToProps = dispatch => ({
    ...mapDispatch(dispatch),
    initCurrentSermon: (sermon) => dispatch(setCurrentSermon(sermon)),
    onResize: (isMobile) => {
        dispatch(setIsMobile(isMobile))
    },
    setInitialized: (val) => dispatch(setInitialized(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IMPACTMain)
