const ACTION_NAME = 'GENERAL';
const ACTION = (action) => `${ACTION_NAME}_${action}`;
// ACTIONS
export const SET = ACTION('SET');
export const SET_SHADOWS = ACTION('SET_SHADOWS');
export const REMOVE = ACTION('RM');
export const GET = ACTION('GET');

export const Actions = {
    SET, GET, REMOVE, SET_SHADOWS,
};
export default Actions;

// SETTERS
export const set = (key, val) => ({
    type: SET, key, val
});

export const remove = (key, val) => ({
    type: REMOVE, key, val,
});

export const setIsMobile = (val) => set('isMobile', val);

export const setHeaderShadow = (val) => set('headerShadow', val ? ' softShadow ' : '');

export const setElementShadow = (key, val) => ({
    type: SET_SHADOWS,
    key,
    val: !!val,
    // val: val ? true : false,
});

// GETTERS


// MAPPING
export const mapStateToProps = (state) => state;

