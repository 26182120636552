import React from 'react';
import {Link} from "react-router-dom";
import {toggleBodyScroll} from "../../../../../utils/articles";
import SVG from "../../../SVGComponent";
import Icons from "../../../../../Assets/Icons";

export const ArticleHeaderNav = () => {
    return (
        <div className={'article-close wrapper'}>
            <Link to={'/'} onClick={() => {
                toggleBodyScroll(false);
            }}>
                <div className={'icon wrapper'}>
                    <SVG {...Icons.navigation.close}/>
                </div>
            </Link>
        </div>
    );
};
