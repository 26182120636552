import Actions from '../actions/ActionsNavigation';

const initialState = {};

const ReducerNavigation = (state = initialState, payload) => {
    let {type,} = payload;
    let newState = Object.assign({}, state);

    switch (type) {
        case Actions.ADD:
            break;
        case Actions.SET:
            break;
        case Actions.REMOVE:
            break;
        default:
            break;
    }

    return newState;
};

export default ReducerNavigation;