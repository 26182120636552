import React from 'react'
import SVG from '../SVGComponent'
import Logos from '../../../Assets/Logos'
import './style.sass'

export const SplashPage = ({visible}) => {
    return (
        <div id={'splash-view'} className={`${visible ? '' : 'out'} trans`}>
            <div className={'wrapper container splash'}>
                <div className={'wrapper logo'}>
                    <SVG {...Logos.impact}/>
                </div>
                <div className={'wrapper text'}>
                    <h3>Deine Gemeinde im Herzen von Frankfurt.</h3>
                    <i>
                        <span>
                            Noch einen kleinen Moment...
                        </span>
                    </i>
                </div>
            </div>
        </div>
    );
};

export default SplashPage;