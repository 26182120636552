import React from 'react';

export const SVG = props => {
    let {id, src, manual, className} = props;
    if ('manual' in props && manual) {
        return (
            <svg key={id} className={className ? className : ''}>
                <use xlinkHref={`${src}${id ? '#' + id : ''}`}/>
            </svg>
        )
    }

    let Svg = src;

    return <Svg key={id}/>
};

export default SVG;


