import {connect} from "react-redux";
import {
    mapDispatchToProps as mapDispatch,
    mapStateToProps as mapProps
} from "../../../../redux/actions/ActionsArticles";
import Contact from "./Contact";

const mapStateToProps = mapProps;

const mapDispatchToProps = mapDispatch;

export default connect(mapStateToProps, mapDispatchToProps)(Contact)