import {articlesUrl} from '../redux/Urls';
import {mobileMaxThreshold} from './Constants';

export const fetchData = (url, setState = false) => {
    if (url.trim().length <= 0) return;

    return fetch(url)
        .then(resp => {
            let json = resp.json();

            if (setState || setState === Function) {
                setState(json);
            }
            else {
                return json
            }
        });
};

export const fetchDocument = (id) => {
    return fetchData(articlesUrl)
        .then(json => {
        });
};

export function objToArr(obj) {
    return Object.keys(obj).map((key, idx, art) => {
        let val = obj[key];
        val['_key'] = key;
        return val;
    })
}

export const sortByIndex = (array) => {
    return array.sort((a, b) => {
        return (a.index < b.index) ? -1 : 1;
    });
};

export const isMobile = () => {
    return window.innerWidth < mobileMaxThreshold
};

export function toSet(arr) {
    return Array.from(new Set(arr))
}
