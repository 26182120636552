import React from 'react';
import {Link} from "react-router-dom";
import '../../Styles/ErrorPages.sass'

export const Error404 = (props) => {
    return (
        <div id={"error"} className={'error wrapper'}>
            <div className={"error code bg wrapper"}>
                <p>
                    <span className={'err-code'}>404</span> - <span>Seite nicht gefunden.</span>
                </p>
            </div>
            <main>
                <h3 id={'bible-verse'}>
                    "Bittet, so wird euch gegeben;
                    suchet, so werdet ihr finden;
                    klopfet an, so wird euch aufgetan."
                </h3>
                <h5>
                    Matthäus 7:7
                </h5>
                <Link to={'/'}>
                    <div className={'home-link trans long'}>
                        <h5>Zur Startseite</h5>
                    </div>
                </Link>
            </main>
        </div>
    );
};

export default Error404;