const initialState = {
    initialized: false,
};

const ReducerMain = (state = initialState, payload) => {
    let newState = Object.assign({}, state);

    switch (payload.type) {
        case 'SET':
            let {key, val} = payload;
            newState[key] = val;
            break;
        default:
            break;
    }
    return newState;
};

export default ReducerMain;
