import {connect} from "react-redux";
import Router from "./Router";
import {mapStateToProps as mapProps} from '../../../redux/actions/ActionsMain'

const mapStateToProps = mapProps;

const mapDispatchToProps = dispatch => ({});


export default connect(mapStateToProps, mapDispatchToProps)(Router)
