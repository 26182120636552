import React, {Component} from 'react'
import '../../Styles/ArticleTemplate.sass'
import {gradients, toggleArticle, toggleBodyScroll} from "../../../utils/articles";
import GradientWrapper from "../../Stateless/GradientWrapper";
import ArticleHeaderNav from "../../Stateless/_Articles/_Part/ArticleHeaderNav";

export class ArticleTemplate extends Component {

    printArticle = () => {
        if (this.props.activeArticle === {}) {
            return (<div className={'empty article'}/>)
        } else {
            return (
                <GradientWrapper gradient={this.state.gradients}>
                    <div>
                        <ArticleHeaderNav/>

                        <article className={'article'}>
                            <h4 className={'subject'}>{this.state.preTitle}</h4>
                            <h1 className={'title'}>{this.state.title}</h1>
                            <div className={'paragraphs'}>
                                {this.state.content.map((p, idx) => {
                                    return (
                                        <p key={`${p[Math.floor((Math.random() * p.length))]}-${idx}`}>{p}</p>
                                    )
                                })}
                            </div>
                        </article>
                    </div>
                </GradientWrapper>
            )
        }
    };

    componentWillMount() {
        let state = {
            loading: true,
            gradients: [
                gradients.blue1,
            ],
        };

        this.setState(state);
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if (Object.entries(nextProps.articles).length > 0 && this.state.loading) {
            const active = nextProps.articles[this.props.match.params.title];
            const {href, title, index, _content} = active;
            const preTitle = active['pre_title'];
            toggleArticle(true);
            toggleBodyScroll(this.props.visible);
            this.setState({
                loading: false,
                index: index,
                title: title,
                content: _content,
                href: href,
                preTitle: preTitle,
            });
        }
    }

    componentDidMount() {
        this.setState({
            loading: !this.state.title
        })
    }

    render() {
        console.log(this.props);
        return (
            <div>
                ARTICLE
            </div>
        )
        // if (this.state.loading) {
        //     return (
        //         <div>
        //             ARTICLE
        //         </div>
        //     )
        // }
        // return this.printArticle();
    }
}

export default ArticleTemplate;
