import ReducerMain from './ReducerMain'
import ReducerHeader from './ReducerHeader'
import ReducerArticles from './ReducerArticles'
import ReducerGeneral from './ReducerGeneral'
import ReducerNavigation from './ReducerNavigation';
import ReducerSermons from './ReducerSermons';

const reducers = {
    general: ReducerGeneral,
    main: ReducerMain,
    header: ReducerHeader,
    articles: ReducerArticles,
    navigation: ReducerNavigation,
    sermons: ReducerSermons,
};

export default reducers;