import React, {Component} from 'react'
import ReactDOM from 'react-dom'
import {BrowserRouter as RRouter, Route, Switch} from 'react-router-dom'
import ArticleTemplate from '../../Stateful/ArticleTemplate'
import Home from '../../Stateless/Home'
import Error404 from '../../Stateless/ErrorPages/Error404'
import Events from '../../Stateless/_Articles/Events'
import Contact from '../../Stateless/_Articles/Contact'
import Privacy from '../../Stateless/_Articles/Privacy'
import Impress from '../../Stateless/_Articles/Impress'

export class Router extends Component {

    componentWillUpdate(nextProps, nextState, nextContext) {
        console.log({nextProps, nextState});
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !((nextProps === this.props) || (nextState === this.state))
    }

    render() {
        console.log("ROUTER RE");
        return (
            <RRouter>
                <Switch>
                    <Route path={'/'}
                           exact strict component={() => <Home/>}/>

                    <Route exact
                           path={'/(artikel|articles)/:title'}
                           render={(props) => {
                               console.error("aaa", props);
                               ReactDOM.render(<ArticleTemplate {...props}/>, document.getElementById("header-root"))
                           }}/>

                    <Route exact
                           path={'/(calendar|kalender)/(termine|events)'}
                           render={(props) => <Events {...props}/>}/>

                    <Route exact
                           path={'/(contact|kontakt)'}
                           render={(props) => <Contact {...props}/>}/>

                    <Route exact
                           path={'/(impressum|impress)'}
                           render={(props) => <Impress {...props}/>}/>
                    <Route exact
                           path={'/(datenschutz|rechtliches|agb|privacy|legal)'}
                           component={(props) => <Privacy {...props}/>}/>

                    <Route path={'*'}
                           render={(props) => <Error404 {...props}/>}/>
                </Switch>
            </RRouter>
        );
    }
}

export default Router;
