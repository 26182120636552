import React from 'react'
import ReactDOM from 'react-dom'
import './Styles/index.sass'
import * as serviceWorker from './serviceWorker'
import IMPACTMain from './Components/Stateful/IMPACTMain'
import {LoadsContext} from 'react-loads'
import ReduxProvider from './redux/Provider'

// TODO: load from cookies on mount
// TODO: THEN > asyncFetch

ReactDOM.render(
    ReduxProvider(
        <LoadsContext.Provider>
            <IMPACTMain resetCookies/>
        </LoadsContext.Provider>
    ),
    document.getElementById('impact-app')
);

serviceWorker.unregister();
