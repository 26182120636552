import React, {Component} from 'react';
import '../../Styles/Footer.sass';
import Glyphs from '../../../Assets/Glyphs';
import FooterMaps from '../../Stateless/FooterMaps';
import footerRoutes from '../../../Routing/urls/FooterRoutes';
import {objToArr} from "../../../utils/HelperFunctions";
import FooterCol from "../../Stateless/FooterCol";

class Footer extends Component {

    heart_glyph = (hover = false) => {
        let glyph = Glyphs.heart.inactive;
        if (hover) {
            glyph = Glyphs.heart.active;
        }
        this.setState({
            heartState: glyph
        })
    };

    constructor(props) {
        super(props);
        this.state = {
            heartState: Glyphs.heart.inactive,
        };
    }

    render() {
        let footer = <FooterMaps/>;
        return (
            <footer id='footer'>
                <div className={'footer wrapper'}>
                    <div className={'coloumns wrapper'}>{
                        objToArr(footerRoutes).map((col, idx, arr) => {
                            let {links} = col;
                            col['links'] = objToArr(links);
                            return <FooterCol key={idx} {...col}/>;
                        })
                    }</div>

                    <div className={'last wrapper'}>

                        <h6 className={'small'}
                            onMouseEnter={() => this.heart_glyph(true)}
                            onMouseLeave={() => this.heart_glyph(false)}>
                            <span>Made with</span>
                            <span className={this.state.heartState}/>
                            <span> in Frankfurt!</span>
                        </h6>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
