const ACTION_NAME = 'HEADER';
const ACTION = (action) => `${ACTION_NAME}_${action}`;
// ACTIONS
export const SET = ACTION('SET');
export const GET = ACTION('GET');
export const TOGGLE_SHADOW = ACTION('TOGGLE_SHADOW');

const Actions = {
    SET, GET, TOGGLE_SHADOW,
};
export default Actions;

// SETTERS


// GETTERS


// MAPPING
export const mapStateToProps = (state) => ({
    ...state.header,
    ...state.general,
    ...state.general,
});

